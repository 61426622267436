import React, {useState} from "react";
import Header from "../../components/generic/header";
import {useNavigate} from "react-router-dom";

export default function Contact() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        client_type: 'client-professionnel',
        project_type: 'project-fullcode',
    });

    function handleInputChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData({
            ...formData, [name]: value
        });
    }

    async function handleSubmit(e) {
        e.preventDefault();
        const url = process.env.REACT_APP_TESSA_URL + "/api/form/contact/submit";
        const apiKey = process.env.REACT_APP_TESSA_APIKEY;
        const form = e.target;

        const data = {
            client_type: form.client_type ? form.client_type.value : "",
            client_nom: form.client_nom.value,
            client_prenom: form.client_prenom.value,
            client_email: form.client_email.value,
            project_type: form.project_type ? form.project_type.value : "",
            project_other: form.project_other ? form.project_other.value : "",
            entreprise_nom: form.entreprise_nom ? form.entreprise_nom.value : "",
            message: form.message.value,
        };

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`
        };

        //Appel de l'API
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
        });

        const result = await response.json();

        if (result.trackingNumber) {
            navigate('/contact/sended?trackingNumber=' + result.trackingNumber);
        }
    }

    function EntrepriseName() {
        if (formData.client_type !== 'client-particulier') {
            return (<div className="mb-2 part-professionnel">
                <label htmlFor="nom-entreprise" className="required form-label">Nom de
                    l'entreprise</label>
                <input type="text"
                       required
                       name="entreprise_nom"
                       placeholder="DOE Peinture"
                       id="nom-entreprise"
                       defaultValue={formData.entreprise_nom}
                       className="form-control"/>
            </div>);
        } else {
            return null;
        }
    }

    function ProjectOther() {
        if (formData.project_type === "project-other") {
            return (<div id="input-project-other" className="form-floating">
                <label htmlFor="other" className="required form-label">Autre</label>
                <input type="text"
                       name="project_other"
                       defaultValue={formData.project_other}
                       required
                       className="form-control"
                       placeholder="Mon projet"
                       id="other"/>
            </div>);
        } else {
            return null;
        }
    }

    return (<>
        <Header title="Contactez-nous"/>
        <main>
            <section className="page-padding">
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <h3>Votre projet concerne...</h3>
                        <div className="mb-2">
                            <ul className="form-choices" id="project-selection-container">
                                <li>
                                    <input type="radio"
                                           defaultChecked
                                           name="project_type"
                                           id="project-fullcode"
                                           defaultValue="project-fullcode"
                                           onChange={handleInputChange}/>
                                    <label htmlFor="project-fullcode">
                                        Site Full-Code
                                    </label>
                                </li>
                                <li>
                                    <input type="radio"
                                           name="project_type"
                                           id="project-wordpress"
                                           defaultValue="project-wordpress"
                                           onChange={handleInputChange}/>
                                    <label htmlFor="project-wordpress">
                                        Site Wordpress
                                    </label>
                                </li>
                                <li>
                                    <input type="radio"
                                           name="project_type"
                                           id="project-other"
                                           defaultValue="project-other"
                                           onChange={handleInputChange}/>
                                    <label htmlFor="project-other">
                                        Autre
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <ProjectOther/>
                    </div>
                    <div>
                        <h3>Vous êtes ?</h3>
                        <div className="mb-3 row" id="client-type-selection-container">
                            <div>
                                <input type="radio"
                                       required
                                       name="client_type"
                                       id="client-particulier"
                                       defaultValue="client-particulier"
                                       onChange={handleInputChange}
                                       className={"form-radio"}/>
                                <label htmlFor="client-particulier">Particulier</label>
                            </div>
                            <div>
                                <input type="radio"
                                       required
                                       defaultChecked
                                       name="client_type"
                                       id="client-professionnel"
                                       defaultValue="client-professionnel"
                                       onChange={handleInputChange}
                                       className={"form-radio"}/>
                                <label htmlFor="client-professionnel">Professionnel</label>
                            </div>
                        </div>
                        <EntrepriseName/>
                        <div className="form-row">
                            <div className="mb-2">
                                <div>
                                    <label htmlFor="nom" className="required form-label">Nom</label>
                                    <input type="text" required name="client_nom" placeholder="DOE"
                                           id="nom" className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="mb-2">
                                <div>
                                    <label htmlFor="prenom" className="required form-label">Prénom</label>
                                    <input type="text"
                                           name="client_prenom"
                                           required
                                           placeholder="John"
                                           id="prenom"
                                           className="form-control"/>
                                </div>
                            </div>
                        </div>
                        <div className="mb-2">
                            <label htmlFor="email" className="required form-label">Adresse e-mail</label>
                            <input type="email"
                                   name="client_email"
                                   required
                                   placeholder="johndoe@example.com"
                                   id="email"
                                   className="form-control"/>
                        </div>
                        <div className="mb-2">
                            <label htmlFor="message" className="required form-label">Message</label>
                            <textarea name="message"
                                      id="message"
                                      required
                                      className="form-control"/>
                        </div>
                    </div>
                    <div>
                        <button className="btn btn-primary">Envoyer
                        </button>
                    </div>
                </form>
            </section>
        </main>
    </>);
}