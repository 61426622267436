import React from "react";
import {Link} from "react-router-dom";

export default function Footer() {
    return (
        <footer>
            <div>
                <div>
                    <div>
                        <img
                            src="https://res.cloudinary.com/seventeen-informatique/image/upload/v1704554040/identity/xvpr06elytiztfwir6ix.svg"
                            alt="SEVENTEEN INFORMATIQUE" className="footer-logo"/>
                        <p>SEVENTEEN INFORMATIQUE</p>
                        <p>La Tour du Pin, 38110</p>
                        <p>France</p>
                        <p><a href="https://seventeeninfo.fr">www.seventeeninfo.fr</a></p>
                        <div className={'socials-container'}>
                            <a href="https://instagram.com/seventeen_informatique" target="_blank" title="Instagram">
                                <svg xmlns="http://www.w3.org/2000/svg" width="2500" height="2500"
                                     viewBox="0 0 2499.899999999999 2500">
                                    <path
                                        d="M833.4 1250c0-230.11 186.49-416.7 416.6-416.7s416.7 186.59 416.7 416.7-186.59 416.7-416.7 416.7-416.6-186.59-416.6-416.7m-225.26 0c0 354.5 287.36 641.86 641.86 641.86s641.86-287.36 641.86-641.86S1604.5 608.14 1250 608.14 608.14 895.5 608.14 1250m1159.13-667.31a150 150 0 1 0 150.06-149.94h-.06a150.07 150.07 0 0 0-150 149.94M745 2267.47c-121.87-5.55-188.11-25.85-232.13-43-58.36-22.72-100-49.78-143.78-93.5s-70.88-85.32-93.5-143.68c-17.16-44-37.46-110.26-43-232.13-6.06-131.76-7.27-171.34-7.27-505.15s1.31-373.28 7.27-505.15c5.55-121.87 26-188 43-232.13 22.72-58.36 49.78-100 93.5-143.78s85.32-70.88 143.78-93.5c44-17.16 110.26-37.46 232.13-43 131.76-6.06 171.34-7.27 505-7.27s373.28 1.31 505.15 7.27c121.87 5.55 188 26 232.13 43 58.36 22.62 100 49.78 143.78 93.5s70.78 85.42 93.5 143.78c17.16 44 37.46 110.26 43 232.13 6.06 131.87 7.27 171.34 7.27 505.15s-1.21 373.28-7.27 505.15c-5.55 121.87-25.95 188.11-43 232.13-22.72 58.36-49.78 100-93.5 143.68s-85.42 70.78-143.78 93.5c-44 17.16-110.26 37.46-232.13 43-131.76 6.06-171.34 7.27-505.15 7.27s-373.28-1.21-505-7.27M734.65 7.57c-133.07 6.06-224 27.16-303.41 58.06C349 97.54 279.38 140.35 209.81 209.81S97.54 349 65.63 431.24c-30.9 79.46-52 170.34-58.06 303.41C1.41 867.93 0 910.54 0 1250s1.41 382.07 7.57 515.35c6.06 133.08 27.16 223.95 58.06 303.41 31.91 82.19 74.62 152 144.18 221.43S349 2402.37 431.24 2434.37c79.56 30.9 170.34 52 303.41 58.06C868 2498.49 910.54 2500 1250 2500s382.07-1.41 515.35-7.57c133.08-6.06 223.95-27.16 303.41-58.06 82.19-32 151.86-74.72 221.43-144.18s112.18-139.24 144.18-221.43c30.9-79.46 52.1-170.34 58.06-303.41 6.06-133.38 7.47-175.89 7.47-515.35s-1.41-382.07-7.47-515.35c-6.06-133.08-27.16-224-58.06-303.41-32-82.19-74.72-151.86-144.18-221.43S2150.95 97.54 2068.86 65.63c-79.56-30.9-170.44-52.1-303.41-58.06C1632.17 1.51 1589.56 0 1250.1 0S868 1.41 734.65 7.57"
                                        fill="url(#a)"/>
                                    <path
                                        d="M833.4 1250c0-230.11 186.49-416.7 416.6-416.7s416.7 186.59 416.7 416.7-186.59 416.7-416.7 416.7-416.6-186.59-416.6-416.7m-225.26 0c0 354.5 287.36 641.86 641.86 641.86s641.86-287.36 641.86-641.86S1604.5 608.14 1250 608.14 608.14 895.5 608.14 1250m1159.13-667.31a150 150 0 1 0 150.06-149.94h-.06a150.07 150.07 0 0 0-150 149.94M745 2267.47c-121.87-5.55-188.11-25.85-232.13-43-58.36-22.72-100-49.78-143.78-93.5s-70.88-85.32-93.5-143.68c-17.16-44-37.46-110.26-43-232.13-6.06-131.76-7.27-171.34-7.27-505.15s1.31-373.28 7.27-505.15c5.55-121.87 26-188 43-232.13 22.72-58.36 49.78-100 93.5-143.78s85.32-70.88 143.78-93.5c44-17.16 110.26-37.46 232.13-43 131.76-6.06 171.34-7.27 505-7.27s373.28 1.31 505.15 7.27c121.87 5.55 188 26 232.13 43 58.36 22.62 100 49.78 143.78 93.5s70.78 85.42 93.5 143.78c17.16 44 37.46 110.26 43 232.13 6.06 131.87 7.27 171.34 7.27 505.15s-1.21 373.28-7.27 505.15c-5.55 121.87-25.95 188.11-43 232.13-22.72 58.36-49.78 100-93.5 143.68s-85.42 70.78-143.78 93.5c-44 17.16-110.26 37.46-232.13 43-131.76 6.06-171.34 7.27-505.15 7.27s-373.28-1.21-505-7.27M734.65 7.57c-133.07 6.06-224 27.16-303.41 58.06C349 97.54 279.38 140.35 209.81 209.81S97.54 349 65.63 431.24c-30.9 79.46-52 170.34-58.06 303.41C1.41 867.93 0 910.54 0 1250s1.41 382.07 7.57 515.35c6.06 133.08 27.16 223.95 58.06 303.41 31.91 82.19 74.62 152 144.18 221.43S349 2402.37 431.24 2434.37c79.56 30.9 170.34 52 303.41 58.06C868 2498.49 910.54 2500 1250 2500s382.07-1.41 515.35-7.57c133.08-6.06 223.95-27.16 303.41-58.06 82.19-32 151.86-74.72 221.43-144.18s112.18-139.24 144.18-221.43c30.9-79.46 52.1-170.34 58.06-303.41 6.06-133.38 7.47-175.89 7.47-515.35s-1.41-382.07-7.47-515.35c-6.06-133.08-27.16-224-58.06-303.41-32-82.19-74.72-151.86-144.18-221.43S2150.95 97.54 2068.86 65.63c-79.56-30.9-170.44-52.1-303.41-58.06C1632.17 1.51 1589.56 0 1250.1 0S868 1.41 734.65 7.57"/>
                                </svg>
                            </a>
                            <a href="https://github.com/seventeen-informatique" target="_blank" title="Github">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path
                                        d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div className={'plan-container'}>
                    <div>
                        <div className={'mb-1'}>
                            <h2>Services</h2>
                            <ul>
                                <li>
                                    <Link to={'/site-full-code'}>Full Code</Link>
                                </li>
                                <li>
                                    <Link to={'/site-wordpress'}>Wordpress</Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2>Team</h2>
                            <ul>
                                <li>
                                    <Link to={'/team/csamard'}>Corentin</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div className="mb-1">
                            <h2>Nos réalisations</h2>
                            <ul>
                                <li>
                                    <Link to="/realisations">Nos réalisations</Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2>Contact</h2>
                            <ul>
                                <li>
                                    <Link to={'/contact'}>Nous contactez</Link>
                                </li>
                                <li>
                                    <Link to={'/contact/track'}>Suivre un message</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div className="mb-1">
                            <h2>Soundtrack</h2>
                            <ul>
                                <li>
                                    <Link to={'/soundtrack'}>Soundtrack</Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2>Legals</h2>
                            <ul>
                                <li>
                                    <Link to={'/legals/mentions'}>Mentions Legales</Link>
                                </li>
                                <li>
                                    <Link to={'/legals/cookies'}>Gestion des Cookies</Link>
                                </li>
                                <li>
                                    <Link to={'/legals/cgu'}>Conditions générales d'utilisation (CGU)</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <p>&copy; SEVENTEEN INFORMATIQUE - <Link to={'/legals/cookies'}>Gestion des Cookies</Link> - <Link to={'/legals/mentions'}>Mentions légales</Link>
                </p>
            </div>
        </footer>
    );
}