import React, {useState, useEffect} from "react";
import Header from "../../components/generic/header";
import {useNavigate} from "react-router-dom";

function FormInput({searchBy}) {
    if (searchBy === 'trackingNumber') {
        return (
            <div className="mb-2">
                <label htmlFor="trackingNumber" className="form-label">Numéro de suivie</label>
                <input type="text" className="form-control" id="trackingNumber" name={"trackingNumber"}
                       placeholder="FR65SSVC541D51DD"/>
                <p className={"form-help"}>Vous trouverez le numéro de suivi dans l'e-mail de confirmation de
                    réception de votre message.</p>
            </div>
        );
    }
    return (
        <div className={"mb-2"}>
            <label htmlFor="email" className={'form-label'}>Adresse email</label>
            <input type="email" className={"form-control"} name={"email"} id={"email"}
                   placeholder={"martin@example.com"}/>
            <p className={"form-help"}>En cas d'oubli, veuillez saisir votre adresse e-mail pour retrouver le
                numéro.</p>
        </div>
    );
}

function TrackBar({closed, beingProcessed}){
    let closedState = closed ? 'state-ended' : '';
    let beingProcessedState = beingProcessed ? 'state-ended' : '';

    return (
        <>
            <div className="mb-5">
                <div className="state-ended">
                    <div>

                    </div>
                    <div>
                        <p>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 8V2L10 4" stroke="#292D32" strokeWidth="1.5"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12 2L14 4" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                                <path
                                    d="M7 12C3 12 3 13.79 3 16V17C3 19.76 3 22 8 22H16C20 22 21 19.76 21 17V16C21 13.79 21 12 17 12C16 12 15.72 12.21 15.2 12.6L14.18 13.68C13 14.94 11 14.94 9.81 13.68L8.8 12.6C8.28 12.21 8 12 7 12Z"
                                    stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M5 12V10C5 7.99004 5 6.33004 8 6.04004" stroke="#292D32"
                                      strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                                <path d="M19 12V10C19 7.99004 19 6.33004 16 6.04004" stroke="#292D32"
                                      strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                            Message envoyé
                        </p>
                    </div>
                </div>
                <div className={beingProcessedState}>
                    <div>

                    </div>
                    <div>
                        <p>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M20 12.2V13.9C20 17.05 18.2 18.4 15.5 18.4H6.5C3.8 18.4 2 17.05 2 13.9V8.5C2 5.35 3.8 4 6.5 4H9.2C9.07 4.38 9 4.8 9 5.25V9.15002C9 10.12 9.32 10.94 9.89 11.51C10.46 12.08 11.28 12.4 12.25 12.4V13.79C12.25 14.3 12.83 14.61 13.26 14.33L16.15 12.4H18.75C19.2 12.4 19.62 12.33 20 12.2Z"
                                    stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M22 5.25V9.15002C22 10.64 21.24 11.76 20 12.2C19.62 12.33 19.2 12.4 18.75 12.4H16.15L13.26 14.33C12.83 14.61 12.25 14.3 12.25 13.79V12.4C11.28 12.4 10.46 12.08 9.89 11.51C9.32 10.94 9 10.12 9 9.15002V5.25C9 4.8 9.07 4.38 9.2 4C9.64 2.76 10.76 2 12.25 2H18.75C20.7 2 22 3.3 22 5.25Z"
                                    stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M7.40002 22H14.6" stroke="#292D32" strokeWidth="1.5"
                                      strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M11 18.3999V21.9999" stroke="#292D32" strokeWidth="1.5"
                                      strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M18.4955 7.25H18.5045" stroke="#292D32" strokeWidth="1.5"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M15.6957 7.25H15.7047" stroke="#292D32" strokeWidth="1.5"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12.8954 7.25H12.9044" stroke="#292D32" strokeWidth="1.5"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            En cours de traitement...
                        </p>
                    </div>
                </div>
                <div className={closedState}>
                    <div>

                    </div>
                    <div>
                        <p>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M22 7.42993V13.4299C22 14.9299 21.5 16.1799 20.62 17.0599C19.75 17.9299 18.5 18.4299 17 18.4299V20.5599C17 21.3599 16.11 21.84 15.45 21.4L11 18.4299H8.88C8.96 18.1299 9 17.8199 9 17.4999C9 16.4799 8.61 15.54 7.97 14.83C7.25 14.01 6.18 13.4999 5 13.4999C3.88 13.4999 2.86 13.96 2.13 14.71C2.04 14.31 2 13.8799 2 13.4299V7.42993C2 4.42993 4 2.42993 7 2.42993H17C20 2.42993 22 4.42993 22 7.42993Z"
                                    stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M9 17.5C9 17.82 8.96 18.13 8.88 18.43C8.79 18.83 8.63001 19.22 8.42001 19.56C7.73001 20.72 6.46 21.5 5 21.5C3.97 21.5 3.04 21.11 2.34 20.47C2.04 20.21 1.77999 19.9 1.57999 19.56C1.20999 18.96 1 18.25 1 17.5C1 16.42 1.43 15.43 2.13 14.71C2.86 13.96 3.88 13.5 5 13.5C6.18 13.5 7.25 14.01 7.97 14.83C8.61 15.54 9 16.48 9 17.5Z"
                                    stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M6.06995 18.5399L3.94995 16.4299" stroke="#292D32" strokeWidth="1.5"
                                      strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M6.04993 16.46L3.92993 18.5699" stroke="#292D32" strokeWidth="1.5"
                                      strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M8.5 10.5H15.5" stroke="#292D32" strokeWidth="1.5"
                                      strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            Clos
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

async function Track({trackingNumber, setTrackingNumber, email}) {
    const apiUrl = process.env.REACT_APP_TESSA_URL + "/api/form/contact/track";
    const apiUrlEmail = apiUrl + "/email";
    const apiKey = process.env.REACT_APP_TESSA_APIKEY;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + apiKey
    };

    if (email) {
        //Verifier si le format de l'email est valide
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
            alert("Veuillez saisir une adresse email valide.");
            return;
        }
        //Recherche par email - numero de suivie perdu
        const response = await fetch(apiUrlEmail, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                trackingEmail: email
            })
        });
        const data = await response.json();
        if(response.ok && data.messages && data.messages.length > 0) {
            alert("Un email vous a été envoyé avec la liste de toutes vos conversations.");
        } else {
            alert("Aucun message n'a été trouvé, ou une erreur est survenue.");
        }
    } else if (trackingNumber) {
        //Suivie par numero
        const response = await fetch(apiUrl + "?trackingNumber=" + trackingNumber, {
            method: 'GET',
            headers: headers,
        });
        const data = await response.json();
        if (!response.ok) {
            throw new Error("Une erreur est survenue");
        }

        if (data.message === null) {
            alert("Le numéro de suivie n'a pas été trouvé.");
            setTrackingNumber(null);
            return;
        }

        switch (data.message.projectType) {
            case "project-fullcode":
                data.message.projectType = "Site Fullcode";
                break;
            case "project-wordpress":
                data.message.projectType = "Site WordPress";
                break;
            case "project-other":
                data.message.projectType = data.projectOther;
                break;
            case "project-website":
                data.message.projectType = "Site Web";
                break;
            default:
                break;
        }

        return {
            closed: data.message.Closed,
            beingProcessed: data.message.beingProcessed,
            clientEmail: data.message.clientEmail,
            clientNom: data.message.clientNom,
            clientPrenom: data.message.clientPrenom,
            clientType: data.message.clientType,
            entrepriseNom: data.message.entrepriseNom,
            id: data.message.id,
            message: data.message.message,
            projectOther: data.message.projectOther,
            projectType: data.message.projectType,
            sendedAt: data.message.sendedAt,
            trackingNumber: data.message.trackingNumber,
        }
    }
}

function SectionResultSearch({trackingNumber, setTrackingNumber}) {
    const [data, setData] = useState({
        closed: false,
        beingProcessed: false,
        clientEmail: '',
        clientNom: '',
        clientPrenom: '',
        clientType: '',
        entrepriseNom: '',
        id: '',
        message: '',
        projectOther: '',
        projectType: '',
        sendedAt: '',
        trackingNumber: '',
    });

    const reser = async ({trackingNumber, email}) => {
        const data = await Track({trackingNumber, setTrackingNumber, email});
        setData(data);
    }

    useEffect(() => {
        if (trackingNumber) {
            reser({trackingNumber});
        }
    }, [trackingNumber]);

    console.log(data.closed)
    return (
        <>
            <section className="page-padding message-tracker">
                <TrackBar closed={data.closed} beingProcessed={data.beingProcessed} />
                <div>
                    <div>
                        <h3>Récapitulatif</h3>
                    </div>
                    <div>
                        <p className="mb-0">Status : </p>
                        <p className="mb-0">Type de projet : {data.projectType}</p>
                        <p className="mb-0">Nom : {data.clientNom}</p>
                        <p className="mb-0">Prénom : {data.clientPrenom}</p>
                        <p className="mb-0">Adresse email : {data.clientEmail}</p>
                    </div>
                </div>
                <div>
                    <button className={"btn btn-primary"} onClick={()=>setTrackingNumber(null)}>Modifier le numéro</button>
                </div>
            </section>
            <section className="page-padding">
                <div>
                    <h3>Message</h3>
                </div>
                <div>
                    <p>{data.message}</p>
                </div>
            </section>
        </>
    );
}

function ShowContent() {
    const urlParams = new URLSearchParams(window.location.search);
    const [searchBy, setSearchBy] = useState('trackingNumber');
    const [trackingNumber, setTrackingNumber] = useState(urlParams.get('trackingNumber'));
    const [error, setError] = useState(null);

    if (trackingNumber) {
        return <SectionResultSearch trackingNumber={trackingNumber} setTrackingNumber={setTrackingNumber}/>;
    }

    const Submit = (e) => {
        e.preventDefault();
        const form = e.target;
        // Validation
        if((!form.email || form.email.value === "") && (!form.trackingNumber || form.trackingNumber.value === "")) {
            setError("Veuillez entrer un numéro de suivie ou une adresse email");
            return;
        } else {
            setError(null);
        }

        if (form.trackingNumber && form.trackingNumber.value) {
            // Suivie par numero, on enregistre le numero
            setTrackingNumber(form.trackingNumber.value);
            return;
        }
        Track({
            email: form.email ? form.email.value : "",
            trackingNumber: form.trackingNumber ? form.trackingNumber.value : ""
        });
    }

    return (
        <section className="page-padding">
            <form onSubmit={Submit}>
                <h3>Rechercher via</h3>
                <div className={"mb-2"}>
                    <div className={"mb-1"}>
                        <input type="radio" className={"form-radio"} onClick={(e) => setSearchBy('trackingNumber')}
                               defaultChecked name={"searchBy"} id={"searchByTrackingNumber"} value={"trackingNumber"}/>
                        <label htmlFor="searchByTrackingNumber">Par numéro de suivie</label>
                    </div>
                    <div>
                        <input type="radio" className={"form-radio"} onClick={(e) => setSearchBy('email')}
                               name={"searchBy"} id={"searchByEmail"} value={"email"}/>
                        <label htmlFor="searchByEmail">Par email</label>
                    </div>
                </div>
                <FormInput searchBy={searchBy}/>
                <div>
                    {error && <p className={"form-error"}>{error}</p>}
                </div>
                <div>
                    <button className={'btn btn-primary'}>Rechercher</button>
                </div>
            </form>
        </section>
    );
}

export default function ContactTrack() {
    return (
        <>
            <Header title={"Suivre un message"}/>
            <main>
                <ShowContent/>
            </main>
        </>
    );
}