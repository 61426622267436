import React, { useState } from "react";
import Header from "../../components/generic/header";
import FsLightbox from "fslightbox-react";
import Citation from "../../components/cards/citation";
import CTA from "../../components/generic/cta";

export default function Fullcode(){
    const [togglerCode, setTogglerCode] = useState(false);
    return (
        <>
            <Header title="Services de Création de Site Full-Code" />
            <main>
                <section id="service-introduction" className="page-padding">
                    <div>
                        <div>
                            <h2>C’est quoi le “Full-Code” ?</h2>
                            <p>Le terme “Full-Code” fait référence à la création de sites web à partir de zéro, sans
                                utiliser de systèmes de gestion de contenu (CMS) tels que WordPress ou Joomla. Cela
                                implique que chaque aspect du site web doit être codé manuellement en utilisant des
                                langages de programmation tels que HTML, CSS, JavaScript, PHP, etc. Cette méthode de
                                développement peut être plus complexe et chronophage que l’utilisation d’un CMS, mais
                                elle permet également une personnalisation et un contrôle total sur le code et la
                                structure du site web.</p>
                        </div>
                        <div>
                            <img onClick={() => setTogglerCode(!togglerCode)}
                                 src="https://res.cloudinary.com/seventeen-informatique/image/upload/v1704563541/website/screens/fullcode/ga3atkg7ejpd4uxaebs4.jpg"
                                 alt="Galerie de themes Wordpress permettant de personnaliser l'apparence globale de son site en quelques instant."/>
                            <FsLightbox
                                toggler={togglerCode}
                                sources={["https://res.cloudinary.com/seventeen-informatique/image/upload/v1704563541/website/screens/fullcode/ga3atkg7ejpd4uxaebs4.jpg"]}
                            />
                        </div>
                    </div>
                </section>
                <section id="service-avantages" className="page-padding mb-5">
                    <div className="mb-5">
                        <h2>Pourquoi le “Full-Code” ?</h2>
                    </div>
                    <div>
                        <ul>
                            <li className="card-square">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10.75 22.5001H13.27C14.23 22.5001 14.85 21.8201 14.67 20.9901L14.26 19.1802H9.76L9.35 20.9901C9.17 21.7701 9.85 22.5001 10.75 22.5001Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path
                                        d="M14.26 19.1702L15.99 17.6301C16.96 16.7701 17 16.1701 16.23 15.2001L13.18 11.3302C12.54 10.5202 11.49 10.5202 10.85 11.3302L7.8 15.2001C7.03 16.1701 7.03 16.8001 8.04 17.6301L9.77 19.1702"
                                        stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path d="M12.01 11.1201V13.6501" stroke="#292D32" strokeWidth="1.5"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                    <path
                                        d="M11.15 5.19015L10.37 4.41012C9.9 3.94012 9.9 3.18017 10.37 2.71017L11.15 1.93014C11.62 1.46014 12.38 1.46014 12.85 1.93014L13.63 2.71017C14.1 3.18017 14.1 3.94012 13.63 4.41012L12.85 5.19015C12.38 5.66015 11.62 5.66015 11.15 5.19015Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path
                                        d="M19.45 9.81006H20.55C21.21 9.81006 21.75 10.3501 21.75 11.0101V12.11C21.75 12.77 21.21 13.3101 20.55 13.3101H19.45C18.79 13.3101 18.25 12.77 18.25 12.11V11.0101C18.25 10.3501 18.79 9.81006 19.45 9.81006Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path
                                        d="M4.55 9.81006H3.45C2.79 9.81006 2.25 10.3501 2.25 11.0101V12.11C2.25 12.77 2.79 13.3101 3.45 13.3101H4.55C5.21 13.3101 5.75 12.77 5.75 12.11V11.0101C5.75 10.3501 5.21 9.81006 4.55 9.81006Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path d="M18.54 10.1L13.24 4.80005" stroke="#292D32" strokeWidth="1.5"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5.46 10.1L10.76 4.80005" stroke="#292D32" strokeWidth="1.5"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <p>Personnalisation Complète</p>
                            </li>
                            <li className="card-square">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.08998 13.28H9.17998V20.48C9.17998 22.16 10.09 22.5 11.2 21.24L18.77 12.64C19.7 11.59 19.31 10.72 17.9 10.72H14.81V3.52002C14.81 1.84002 13.9 1.50002 12.79 2.76002L5.21998 11.36C4.29998 12.42 4.68998 13.28 6.08998 13.28Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"
                                        strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <p>Hautes Performances</p>
                            </li>
                            <li className="card-square">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M20.91 11.12C20.91 16.01 17.36 20.59 12.51 21.93C12.18 22.02 11.82 22.02 11.49 21.93C6.63996 20.59 3.08997 16.01 3.08997 11.12V6.72997C3.08997 5.90997 3.70998 4.97998 4.47998 4.66998L10.05 2.39001C11.3 1.88001 12.71 1.88001 13.96 2.39001L19.53 4.66998C20.29 4.97998 20.92 5.90997 20.92 6.72997L20.91 11.12Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path
                                        d="M12 12.5C13.1046 12.5 14 11.6046 14 10.5C14 9.39543 13.1046 8.5 12 8.5C10.8954 8.5 10 9.39543 10 10.5C10 11.6046 10.8954 12.5 12 12.5Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"
                                        strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12 12.5V15.5" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <p>Sécurité Renforcée</p>
                            </li>
                            <li className="card-square">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"
                                        strokeLinecap="round" strokeLinejoin="round"/>
                                    <path
                                        d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"
                                        strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <p>Évolutivité</p>
                            </li>
                        </ul>
                    </div>
                </section>
                <section id="service-fullcode-personnalisation" className="page-padding service-presentation">
                    <div>
                        <div>
                            <h2>Une personnalisation complète</h2>
                            <p>Avec le développement Full-Code, vous avez un contrôle total sur la personnalisation de
                                votre site web. Chaque aspect de la conception peut être entièrement personnalisé pour
                                répondre à vos besoins spécifiques, sans aucune limitation imposée par les systèmes de
                                gestion de contenu (CMS) préexistants.</p>
                        </div>
                        <div>
                            <img
                                src="https://res.cloudinary.com/seventeen-informatique/image/upload/v1704555155/website/illustrations/pjjvyljqwviq3r9n9x5m.svg"
                                alt="A woman in an orange robe is drawing on a whiteboard with a marker and a pencil in his hand"
                                aria-hidden="true" width="100%"/>
                        </div>
                    </div>
                </section>
                <section id="service-fullcode-performances" className="page-padding service-presentation">
                    <div>
                        <div>
                            <h2>Des performances supérieure</h2>
                            <p>Le développement Full-Code offre des performances supérieures pour votre site web en
                                évitant les scripts et les fonctionnalités inutiles. Le code est écrit de manière
                                optimisée pour répondre aux besoins spécifiques du site web, ce qui permet une vitesse
                                de chargement plus rapide et une expérience utilisateur améliorée.</p>
                        </div>
                        <div>
                            <img
                                alt="A man standing in front of a server with his hands on the back of the server's back"
                                src="https://res.cloudinary.com/seventeen-informatique/image/upload/v1704555156/website/illustrations/quxhxtkcb9bq14pzv7to.svg"
                                aria-hidden="true" width="100%"/>
                        </div>
                    </div>
                </section>
                <section id="service-fullcode-securite" className="page-padding service-presentation">
                    <div>
                        <div>
                            <h2>Contrôle total sur la sécurité</h2>
                            <p>Le développement Full-Code vous donne un contrôle total sur la sécurité de votre site
                                web. Vous pouvez ajouter des couches de sécurité personnalisées pour protéger votre site
                                contre les attaques potentielles. Avec un code écrit manuellement, vous pouvez également
                                éviter les vulnérabilités et les failles de sécurité courantes présentes dans les
                                systèmes de gestion de contenu préexistants.</p>
                        </div>
                        <div>
                            <img src="https://res.cloudinary.com/seventeen-informatique/image/upload/v1704555156/website/illustrations/gp8cluskzzwl2z7xmmac.svg" height="250px"
                                 aria-hidden="true"
                                alt="a man in a police uniform is holding a clipboard and a file of papers in one hand and a clipboard in the other hand"/>
                        </div>
                    </div>
                </section>
                <section id="service-fullcode-evolutivite" className="page-padding service-presentation">
                    <div>
                        <div>
                            <h2>Évolutivité sans Limites</h2>
                            <p>Parce que votre entreprise est en constante évolution, vos besoins, tout comme ceux de vos clients, évoluent au fil du temps. C'est là que l'évolutivité d'un site Full-Code devient votre alliée inestimable. Vous n'êtes pas limité par des structures préétablies, vous avez le contrôle total pour faire évoluer votre site en fonction des nouvelles tendances, des demandes changeantes du marché, et des opportunités qui se présentent.</p>
                        </div>
                        <div>
                            <img src="https://res.cloudinary.com/seventeen-informatique/image/upload/v1704555153/website/illustrations/vm1dckbmq2y3zyxziwr7.svg" alt="a group of people standing next to each other on top of a white background with a blue and yellow geometric design" width="100%" aria-hidden="true" />
                        </div>
                    </div>
                </section>
                <section className="page-padding">
                    <Citation author="Corentin S.E."
                              avatar="https://res.cloudinary.com/seventeen-informatique/image/upload/v1704554038/identity/xcu8vy3lrfrkmswvwamz.jpg"
                              content="Chez SEVENTEEN INFORMATIQUE, passionné en matière de développement Web et de création de contenu. Nous sommes fiers de créer des sites web de qualité supérieure pour nos clients, avec des fonctionnalités de pointe et une conception esthétique et professionnelle. Nous pouvons également vous aider à planifier, rédiger et créer du contenu pour votre site web ou votre blog, afin de vous aider à attirer de nouveaux clients et à stimuler votre activité."/>
                </section>
                <section className="mb-5">
                    <CTA title="Un projet en tête ?"
                         text="Prêt à démarrer votre projet ? Contactez-nous dès maintenant et discutons de vos besoins. Nous sommes là pour vous aider à concrétiser vos idées et à atteindre vos objectifs numériques. Cliquez ci-dessous pour nous rejoindre."
                         button="Contactez-nous" link="/contact"
                         picture="https://res.cloudinary.com/seventeen-informatique/image/upload/v1704555157/website/illustrations/kcaqlyez0codwq7gnqnn.svg"/>
                </section>
            </main>
        </>
    );
}