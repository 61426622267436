import React from "react";
import Header from "../../components/generic/header";

export default function Cookies(){
    return (
        <>
            <Header type={'header-min'}/>
            <main>
                <section className="page-padding">
                    <div>
                        <h2>Politique en matière de cookies</h2>
                    </div>
                    <div>
                        <p className="mb-1">Date de dernière mise à jour : 01/11/2023</p>
                        <p className="mb-1">Veuillez lire attentivement les présentes conditions générales d'utilisation
                            (ci-après les "CGU") avant d'utiliser le site de SEVENTEEN INFORMATIQUE (ci-après le
                            "Site"). En utilisant le Site, vous acceptez d'être lié par les présentes CGU. Si vous
                            n'acceptez pas ces CGU, veuillez ne pas utiliser le Site.</p>
                    </div>
                </section>
                <section className="page-padding">
                    <h2>1. Utilisation des cookies</h2>
                    <p>Ce site web utilise des cookies pour améliorer l'expérience de l'utilisateur. En utilisant ce
                        site, vous consentez à l'utilisation de cookies conformément à cette politique.</p>
                </section>

                <section className="page-padding">
                    <h2>2. Qu'est-ce qu'un cookie ?</h2>
                    <p>Un cookie est un petit fichier texte stocké sur votre ordinateur. Il permet au site web de se
                        souvenir de vos actions et de vos préférences, comme la langue que vous avez choisie ou les
                        produits que vous avez ajoutés au panier.</p>
                </section>

                <section className="page-padding">
                    <h2>3. Types de cookies</h2>
                    <p>Il existe différents types de cookies :</p>
                    <ul>
                        <li>Cookies essentiels : nécessaires au bon fonctionnement du site.</li>
                        <li>Cookies de performance : utilisés pour analyser la manière dont les utilisateurs
                            interagissent avec le site.
                        </li>
                        <li>Cookies de fonctionnalité : permettent d'enregistrer les préférences de l'utilisateur.</li>
                    </ul>
                </section>

                <section className="page-padding">
                    <h2>4. Gestion des cookies</h2>
                    <p>Vous pouvez contrôler et gérer les cookies dans les paramètres de votre navigateur. Vous pouvez
                        également supprimer les cookies déjà stockés sur votre ordinateur. La désactivation des cookies
                        peut affecter certaines fonctionnalités du site.</p>
                </section>

                <section className="page-padding">
                    <h2>5. Modifications de la politique</h2>
                    <p>Nous nous réservons le droit de modifier cette politique en matière de cookies à tout moment.
                        Veuillez consulter cette page régulièrement pour rester informé des mises à jour.</p>
                </section>

                <section className="page-padding">
                    <h2>6. Contact</h2>
                    <p>Si vous avez des questions ou des préoccupations concernant cette politique en matière de
                        cookies, veuillez nous contacter à l'adresse suivante : contact[at]seventeeninfo.fr.</p>
                </section>
            </main>
        </>
    );
}