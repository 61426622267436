import React from "react";
import {Link} from "react-router-dom";

function isUrl(url){
    const urlPattern = /^(https?:\/\/)?([\w.-]+\.[a-zA-Z]{2,})(\/\S*)?$/;
    return urlPattern.test(url);
}

function Button({url, text, target}){
    if (isUrl(url)) {
        //Url externe : https://google.fr
        return <a href={url} {...(target && {target})} className="btn btn-light">{text}</a>;
    } else {
        //Chemin interne : /contact
        return <Link to={url} className="btn btn-light">{text}</Link>;
    }
}

export default function CTA({title, text, button, link, picture, target}){
    const formattedText = {
        __html: text
    }

    return (
        <div className="cta">
            <div>
                <h2>{title}</h2>
                <p dangerouslySetInnerHTML={formattedText}/>
                <Button url={link} text={button} target={target} />
            </div>
            <div className="character-container character-phone">
                <img src={picture} alt="Illustration CTA" aria-hidden="true"/>
            </div>
        </div>
    );
}