import React from "react";
import {Link} from "react-router-dom";

//Components
import Header from "../../components/generic/header";
import CardRealisation from "../../components/cards/realisation";
import CTA from "../../components/generic/cta";
import RealisationsGrid from "../../components/realisations/grid";

export default function Home() {
    return (
        <div id="homepage">
            <Header title="Créez Votre <b>Présence en Ligne</b> avec <b>SEVENTEEN INFORMATIQUE</b>"/>
            <main>
                {/*<!--begin::Services-->*/}
                <section id="homepage-services">
                    <div>
                        <h2>Nos Services</h2>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <Link to="/site-wordpress">
                                    <h3>WordPress</h3>
                                    <p>Pour des sites web élégants, rapides à mettre en place et facilement personnalisables.</p>
                                    <p>
                                        En savoir plus
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.4301 5.92993L20.5001 11.9999L14.4301 18.0699" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M3.5 12H20.33" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </p>
                                </Link>
                            </li>
                            <li>
                                <Link to="/site-full-code">
                                    <h3>Full-Code</h3>
                                    <p>Pour des sites web sur mesure, performants, développés de A à Z, adaptés à vos besoins uniques.</p>
                                    <p>
                                        En savoir plus
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.4301 5.92993L20.5001 11.9999L14.4301 18.0699" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M3.5 12H20.33" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </p>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </section>
                {/*<!--end::Services-->*/}
                {/*<!--begin::Presentation-->*/}
                <section id="homepage-presentation" className="page-padding mt-5">
                    <div>
                        <h2>Votre Vision, Notre Expertise</h2>
                        <p>Je suis un développeur web passionné par la création de sites innovants et performants. Du développement sous WordPress au Full-Code, je relève chaque défi avec expertise. Je crée des solutions <b>sur mesure</b> pour répondre à vos besoins spécifiques et optimiser votre présence en ligne.</p>
                        <Link to={'/realisations'} className="btn btn-primary mt-2">Mes Realisations</Link>
                    </div>
                    <div>
                        <img src="https://res.cloudinary.com/seventeen-informatique/image/upload/v1704555153/website/illustrations/vm1dckbmq2y3zyxziwr7.svg" alt="Groupe de personnes entrain d'assembler des formes geometriques."/>
                    </div>
                </section>
                {/*<!--end::Presentation-->*/}
                {/*<!--begin::Last Projects-->*/}
                <section id="last-projects" className="mt-5 mb-5">
                    <div>
                        <h2>Nos derniers projets</h2>
                    </div>
                    <div>
                        <RealisationsGrid limit={3} />
                    </div>
                </section>
                {/*<!--end::Last Projects-->*/}
                <section className="mb-5">
                    <CTA title="Un projet en tête ?" text="Prêt à démarrer votre projet ? Contactez-nous dès maintenant et discutons de vos besoins. Nous sommes là pour vous aider à concrétiser vos idées et à atteindre vos objectifs numériques. Cliquez ci-dessous pour nous rejoindre." button="Contactez-nous" link="/contact" picture="https://res.cloudinary.com/seventeen-informatique/image/upload/v1704555157/website/illustrations/kcaqlyez0codwq7gnqnn.svg"/>
                </section>
            </main>
        </div>
    );
}