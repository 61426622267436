import React, {useState} from "react";
import Header from "../../components/generic/header";
import CardRealisation from "../../components/cards/realisation";
import RealisationsGrid from "../../components/realisations/grid";

export default function Realisations() {
    const [realisationsCount, setRealisationsCount] = useState(0);
    return (
        <>
            <Header title="Nos Réalisations"/>
            <main>
                <section>

                </section>
                <section className="page-padding">
                    <div>
                        <h2>Nos Réalisations</h2>
                        <p>{realisationsCount} Réalisations</p>
                    </div>
                </section>
                <section id="realisations-container">
                    <RealisationsGrid limit={'100'} setRealisationsCount={setRealisationsCount} />
                </section>
            </main>
        </>
    );
}