import React from "react";
import Header from "../../components/generic/header";

export default function LegalsCGU(){
    return (
        <>
            <Header type={'header-min'}/>
            <main>
                <section className="page-padding">
                    <div>
                        <h2>Conditions générales d'utilisation (CGU)</h2>
                    </div>
                    <div>
                        <p className="mb-1">Date de dernière mise à jour : 01/11/2023</p>
                        <p className="mb-1">Veuillez lire attentivement les présentes conditions générales d'utilisation
                            (ci-après les "CGU") avant d'utiliser le site de SEVENTEEN INFORMATIQUE (ci-après le
                            "Site"). En utilisant le Site, vous acceptez d'être lié par les présentes CGU. Si vous
                            n'acceptez pas ces CGU, veuillez ne pas utiliser le Site.</p>
                    </div>
                </section>
                <section className="page-padding">
                    <h2>1. Acceptation des conditions</h2>
                    <p>En utilisant ce site web, vous acceptez de respecter les présentes Conditions Générales
                        d'Utilisation. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser ce site.</p>
                </section>

                <section className="page-padding">
                    <h2>2. Utilisation du site</h2>
                    <p>Vous acceptez de n'utiliser ce site qu'à des fins légales et conformes à ces conditions. Vous ne
                        devez pas utiliser ce site d'une manière qui pourrait causer des dommages, des interruptions ou
                        des problèmes d'accessibilité.</p>
                </section>

                <section className="page-padding">
                    <h2>3. Propriété intellectuelle</h2>
                    <p>Tous les contenus de ce site, y compris les textes, images, logos, etc., sont protégés par des
                        droits d'auteur et appartiennent à l'entreprise SEVENTEEN INFORMATIQUE. Vous ne pouvez pas les
                        reproduire sans autorisation.</p>
                </section>

                <section className="page-padding">
                    <h2>4. Liens vers des sites tiers</h2>
                    <p>Ce site peut contenir des liens vers des sites web tiers. Nous ne sommes pas responsables du
                        contenu de ces sites.</p>
                </section>

                <section className="page-padding">
                    <h2>5. Modification des CGU</h2>
                    <p>Nous nous réservons le droit de modifier ces Conditions Générales d'Utilisation à tout moment.
                        Veuillez consulter cette page régulièrement pour rester informé des mises à jour.</p>
                </section>

                <section className="page-padding">
                    <h2>6. Contact</h2>
                    <p>Si vous avez des questions ou des préoccupations concernant ces Conditions Générales
                        d'Utilisation, veuillez nous contacter à l'adresse suivante : contact[at]seventeeninfo.fr.</p>
                </section>
            </main>
        </>
    );
}