import React from "react";
import Header from "../../components/generic/header";
import {Link} from "react-router-dom";

export default function Legals(){
    return (
        <>
            <Header type={'header-min'} />
            <main>
                <section className="page-padding">
                    <div>
                        <h2>Mentions légales</h2>
                    </div>
                    <div>
                        <p className="mb-1">Nom de l’entreprise : SEVENTEEN INFORMATIQUE</p>
                        <p className="mb-1">Forme Juridique : Auto-entreprise</p>
                        <p className="mb-1">Siège social : 87 rue de la dentellière, 38110 La Bâtie-Montgascon</p>
                        <p className="mb-1">Numéro de SIRET : 97760431300014</p>
                        <p className="mb-1">Responsable légal : Corentin SAMARD–EYMONERIE</p>
                    </div>
                </section>
                <section className="page-padding">
                    <div className="mb-5">
                        <h3>Contact</h3>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <h4 className="mb-2">Par voie éléctronique</h4>
                            <div className="mb-5">
                                <p>contact[at]seventeeninfo.fr</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <h4 className="mb-2">Par voie postale</h4>
                            <div>
                                <p className="mb-1">87 Rue de la dentellière</p>
                                <p>38110 La Bâtie-Montgascon</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="page-padding">
                    <div className="mb-2">
                        <h3>Hébergement du site</h3>
                    </div>
                    <div>
                        <p className="fw-bold mb-1">o2switch</p>
                        <p className="mb-1">Chem. des Pardiaux</p>
                        <p className="mb-1">63000 Clermont-Ferrand</p>
                        <p>04 44 44 60 40</p>
                    </div>
                </section>
                <section className="page-padding">
                    <div className="mb-2">
                        <h3>Protection des données personnelles</h3>
                    </div>
                    <div>
                        <p>SEVENTEEN INFORMATIQUE collecte et traite les données personnelles conformément au Règlement
                            général sur la protection des données (RGPD). Pour en savoir plus sur notre politique de
                            confidentialité et sur la manière dont nous traitons les données personnelles, veuillez
                            consulter notre politique de confidentialité disponible sur notre site.</p>
                    </div>
                </section>
                <section className="page-padding">
                    <div className="mb-2">
                        <h3>Protection intellectuelle</h3>
                    </div>
                    <div>
                        <p>Tous les droits d’auteur et autres droits de propriété intellectuelle relatifs au contenu
                            publié sur ce site sont la propriété de SEVENTEEN INFORMATIQUE ou sont utilisés avec
                            l’autorisation du titulaire des droits. Toute reproduction ou utilisation non autorisée du
                            contenu est strictement interdite.</p>
                    </div>
                </section>
                <section className="page-padding">
                    <div className="mb-2">
                        <h3>Condition générales d’utilisation</h3>
                    </div>
                    <div>
                        <p>Les conditions générales d’utilisation régissent l’utilisation de notre site Internet et les
                            relations contractuelles avec les utilisateurs. Veuillez consulter nos conditions générales
                            d’utilisation pour plus d’informations.</p>
                        <Link to={'/legals/cgu'} className="btn btn-secondary mt-2">Conditions générales
                            d'utilisation (CGU)</Link>
                    </div>
                </section>
                <section className="page-padding">
                    <div className="mb-2">
                        <h3>Cookies</h3>
                    </div>
                    <div>
                        <p>Nous utilisons des cookies sur notre site pour améliorer l’expérience des utilisateurs. En
                            utilisant notre site, vous acceptez l’utilisation de cookies. Pour plus d’informations sur
                            l’utilisation des cookies, veuillez consulter notre politique en matière de cookies.</p>
                        <Link to={'/legals/cookies'} className="btn btn-secondary mt-2">Gestion des Cookies</Link>
                    </div>
                </section>
            </main>
        </>
    );
}