import React from "react";
import Header from "../../components/generic/header";
import CTA from "../../components/generic/cta";

export default function ContactSended() {
    const urlParams = new URLSearchParams(window.location.search);
    const trackingNumber = urlParams.get('trackingNumber');

    return (
        <>
            <Header type="header-min"/>
            <main>
                <section className="page-padding contact-sended-container">
                    <div>
                        <img
                            src="https://res.cloudinary.com/seventeen-informatique/image/upload/v1704791502/website/illustrations/evqcxk6x8auk653ixlu3.svg"
                            width="250px"
                            alt="a woman putting a mail into a red mailbox next to a fire hydrant and a lamp post"/>
                    </div>
                    <div>
                        <div className="mb-2">
                            <h2>Votre demande est envoyée</h2>
                            <p>Votre message a été envoyé avec succès. Nous vous remercions pour votre communication et
                                nous vous répondrons dans les plus brefs délais.</p>
                        </div>
                        <div>
                            <h3>Récapitulatif</h3>
                            <p>Numéro de suivie : {trackingNumber}</p>
                            <p>Un mail vous à été envoyée avec tous les détails.</p>
                        </div>
                    </div>
                </section>
                <section className="mb-5">
                    <CTA title="Ne perdez pas le fil 🔔"
                         text="Restez connecté avec notre service de suivi de messages ! Gardez le contrôle en consultant l'état de vos communications. Accédez à tout moment à la progression de vos messages, du traitement en cours à la disponibilité des réponses."
                         button="Suivre mon message" link={`/contact/track?trackingNumber=${trackingNumber}`}
                         picture="https://res.cloudinary.com/seventeen-informatique/image/upload/v1704555157/website/illustrations/kcaqlyez0codwq7gnqnn.svg"/>
                </section>
            </main>
        </>
    );
}