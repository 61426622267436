import React from "react";

export default function Header({title, type}){
    const formattedTitle = {
        __html: title
    }
    return (
        <header className={type + " header-default"}>
            <div>
                <h2 dangerouslySetInnerHTML={formattedTitle}/>
            </div>
        </header>
    );
}