import React from "react";
import {Link} from "react-router-dom";

//Components
import Header from "../../components/generic/header";
import CTA from "../../components/generic/cta";

export default function Soundtrack() {
    return (
        <div id="soundtrack">
            <Header title="Découvrez le <b>Soundtrack</b> de <b>SEVENTEEN INFORMATIQUE</b>"/>
            <main>
                <section className="page-padding">
                    <div>
                        <p>Chez SEVENTEEN INFORMATIQUE, nous ne nous contentons pas de créer des sites internet sur mesure pour nos clients, nous nous engageons également à offrir une expérience complète et immersive à chaque interaction. Notre musique d'attente personnalisée en est le parfait exemple.</p>
                    </div>
                </section>
                <section className="page-padding">
                    <div>
                        <h2>Un Soundtrack à votre image</h2>
                    </div>
                    <div>
                        <p>Notre soundtrack d'attente a été soigneusement conçu pour refléter les valeurs qui nous tiennent à cœur : <b>proximité</b>, <b>qualité</b>, <b>innovation</b> et <b>satisfaction client</b>. Ce morceau rythmé et énergique vous accompagnera lors de vos appels, vous immergeant dans l'univers dynamique et créatif de SEVENTEEN INFORMATIQUE.</p>
                    </div>
                </section>
                <section className="page-padding">
                    <div>
                        <h2>Paroles Inspirantes et Sur-Mesure</h2>
                    </div>
                    <div>
                        <p>Les paroles de notre chanson d'attente ont été écrites spécialement pour vous, nos clients. Elles mettent en lumière notre engagement à vos côtés, notre expertise en création de sites internet fullstack et WordPress, ainsi que notre volonté de rendre chaque projet unique et personnalisé.</p>
                        <p>Chaque mot a été choisi avec soin pour résonner avec notre mission : vous offrir le meilleur service possible.</p>
                    </div>
                </section>
                <section className="page-padding">
                    <div>
                        <h2>Pourquoi ce Soundtrack ?</h2>
                    </div>
                    <div>
                        <p>Nous avons choisi de créer ce soundtrack pour que votre expérience avec SEVENTEEN INFORMATIQUE soit agréable à chaque étape, même pendant les moments d'attente. Cette musique est une invitation à découvrir notre univers, à vous sentir accompagné et à patienter dans une atmosphère positive et motivante.</p>
                    </div>
                </section>
                <section className="page-padding">
                    <div>
                        <h2>Écoutez notre Soundtrack</h2>
                    </div>
                    <div>
                        <p>Curieux d'en savoir plus ? Cliquez ci-dessous pour écouter notre soundtrack d'attente et plongez dans l'ambiance SEVENTEEN INFORMATIQUE.</p>
                    </div>
                    <div>
                        <iframe
                            width="100%"
                            height={166}
                            scrolling="no"
                            frameBorder="no"
                            allow="autoplay"
                            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1908520886%3Fsecret_token%3Ds-lQaYBR8QPQB&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
                        />
                        <div
                            style={{
                            fontSize: 10,
                            color: "#cccccc",
                            lineBreak: "anywhere",
                            wordBreak: "normal",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            fontFamily:
                                "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
                            fontWeight: 100
                            }}
                        >
                            <a
                            href="https://soundcloud.com/seventeen-informatique"
                            title="SEVENTEEN INFORMATIQUE"
                            target="_blank"
                            style={{ color: "#cccccc", textDecoration: "none" }}
                            >
                            SEVENTEEN INFORMATIQUE
                            </a>{" "}
                            ·{" "}
                            <a
                            href="https://soundcloud.com/seventeen-informatique/seventeen-soundtrack/s-lQaYBR8QPQB"
                            title="SEVENTEEN SoundTrack"
                            target="_blank"
                            style={{ color: "#cccccc", textDecoration: "none" }}
                            >
                            SEVENTEEN SoundTrack
                            </a>
                        </div>
                    </div>
                </section>
                <section className="mb-5">
                    <CTA title="Un Projet Sur-Mesure pour Vous"
                        text="Vous aimez notre soundtrack ? Imaginez ce que nous pouvons faire pour votre site internet ! Chez SEVENTEEN INFORMATIQUE, chaque projet est unique, et nous travaillons main dans la main avec vous pour créer un site qui reflète parfaitement votre vision et vos besoins.<br><br> <b>Contactez-nous dès aujourd'hui</b> pour discuter de votre projet web, et voyons ensemble comment nous pouvons le faire briller."
                        button="Contactez-nous" link="/contact"
                        picture="https://res.cloudinary.com/seventeen-informatique/image/upload/v1704555157/website/illustrations/kcaqlyez0codwq7gnqnn.svg"/>
                </section>
            </main>
        </div>
    );
}