import React from "react";

export default function CardRealisation({ picture, title, description, link }) {
    return (
        <div className={'realisation-card'}>
            <div>
                <a href={link} target="_blank">
                    <img src={picture} alt="Illustration du projet" />
                </a>
            </div>
            <div>
                <div>
                    <h3>{title}</h3>
                    <p>{description}</p>
                </div>
                <div>
                    {link ? (
                        <a href={link} target="_blank" className="realisation-readmore">
                            {/*En savoir plus*/}
                            {/*<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                            {/*    <path d="M14.4301 5.92993L20.5001 11.9999L14.4301 18.0699" stroke="#292D32"*/}
                            {/*          strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"*/}
                            {/*          strokeLinejoin="round"/>*/}
                            {/*    <path d="M3.5 12H20.33" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"*/}
                            {/*          strokeLinecap="round" strokeLinejoin="round"/>*/}
                            {/*</svg>*/}
                            Voir le site
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.99 17.5H16.5C19.52 17.5 22 15.03 22 12C22 8.98 19.53 6.5 16.5 6.5H14.99"
                                    stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M9 6.5H7.5C4.47 6.5 2 8.97 2 12C2 15.02 4.47 17.5 7.5 17.5H9" stroke="#292D32"
                                    strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8 12H16" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                                    strokeLinejoin="round" />
                            </svg>
                        </a>
                    ) : null}
                </div>
            </div>
        </div>
    );
}