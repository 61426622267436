import {BrowserRouter, Routes, Route} from "react-router-dom";
import './assets/css/main.css';

//Components
import Navbar from "./components/naviguation/navbar";

//Pages
import Home from "./pages/home/home";
import Wordpress from "./pages/services/wordpress";
import Fullcode from "./pages/services/fullcode";
import Footer from "./components/generic/footer";
import Realisations from "./pages/realisations/realisations";
import Contact from "./pages/contact/contact";
import ContactSended from "./pages/contact/sended";
import ContactTrack from "./pages/contact/track";
import Legals from "./pages/legals/mentions";
import Cookies from "./pages/legals/cookies";
import LegalsCGU from "./pages/legals/cgu";
import PortfolioCsamard from "./pages/team/csamard";
import Soundtrack from "./pages/soundtrack/soundtrack";

function App() {
    return (<div className="App">
        <BrowserRouter>
            <Navbar/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/site-wordpress" element={<Wordpress/>}/>
                <Route path="/site-full-code" element={<Fullcode/>}/>
                <Route path="/realisations" element={<Realisations/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/team/csamard" element={<PortfolioCsamard/>}/>
                <Route path="/contact/sended" element={<ContactSended/>}/>
                <Route path="/contact/track" element={<ContactTrack/>}/>
                <Route path="/legals/mentions" element={<Legals/>}/>
                <Route path="/legals/cookies" element={<Cookies/>}/>
                <Route path="/legals/cgu" element={<LegalsCGU/>}/>
                <Route path="/soundtrack" element={<Soundtrack/>}/>
            </Routes>
            <Footer/>
        </BrowserRouter>
    </div>);
}

export default App;
