import React from "react";
import FsLightbox from 'fslightbox-react';

//Components
import Header from "../../components/generic/header";
import Citation from "../../components/cards/citation";
import CTA from "../../components/generic/cta";

export default function Wordpress() {
    const [togglerThemes, setTogglerThemes] = React.useState(false);
    const [togglerPlugins, setTogglerPlugins] = React.useState(false);
    const [togglerGutenberg, setTogglerGutenberg] = React.useState(false);

    return (
        <div>
            <Header title="Services de Création de Site WordPress" />
            <main>
                <section id="service-introduction" className="page-padding">
                    <div>
                        <div>
                            <h2>C'est quoi Wordpress ?</h2>
                            <p>WordPress est un système de gestion de contenu (CMS) convivial qui permet de créer
                                facilement des sites web professionnels et des blogs avec un minimum d’efforts
                                techniques. Avec WordPress, la création de contenu devient un jeu d’enfant : vous pouvez
                                facilement ajouter du texte, des images, des vidéos et d’autres types de contenu, ainsi
                                que modifier et mettre à jour votre contenu en quelques clics seulement.</p>
                        </div>
                        <div>
                            <img onClick={() => setTogglerThemes(!togglerThemes)}
                                 src="https://res.cloudinary.com/seventeen-informatique/image/upload/v1704563553/website/screens/wordpress/dpkfnb0jqj8picwfhv7i.png"
                                 alt="Galerie de themes Wordpress permettant de personnaliser l'apparence globale de son site en quelques instant."/>
                            <FsLightbox
                                toggler={togglerThemes}
                                sources={[
                                    "https://res.cloudinary.com/seventeen-informatique/image/upload/v1704563553/website/screens/wordpress/dpkfnb0jqj8picwfhv7i.png"
                                ]}
                            />
                        </div>
                    </div>
                </section>
                <section id="service-avantages" className="page-padding mb-5">
                    <div className="mb-5">
                        <h2>Pourquoi Wordpress ?</h2>
                    </div>
                    <div>
                        <ul>
                            <li className="card-square">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.73 3.51001L15.49 7.03001C15.73 7.52002 16.37 7.99001 16.91 8.08001L20.1 8.61001C22.14 8.95001 22.62 10.43 21.15 11.89L18.67 14.37C18.25 14.79 18.02 15.6 18.15 16.18L18.86 19.25C19.42 21.68 18.13 22.62 15.98 21.35L12.99 19.58C12.45 19.26 11.56 19.26 11.01 19.58L8.01997 21.35C5.87997 22.62 4.57997 21.67 5.13997 19.25L5.84997 16.18C5.97997 15.6 5.74997 14.79 5.32997 14.37L2.84997 11.89C1.38997 10.43 1.85997 8.95001 3.89997 8.61001L7.08997 8.08001C7.61997 7.99001 8.25997 7.52002 8.49997 7.03001L10.26 3.51001C11.22 1.60001 12.78 1.60001 13.73 3.51001Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                </svg>
                                <p>Facilite de Gestion</p>
                            </li>
                            <li className="card-square">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M3 9C4.10457 9 5 8.10457 5 7C5 5.89543 4.10457 5 3 5C1.89543 5 1 5.89543 1 7C1 8.10457 1.89543 9 3 9Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"
                                        strokeLinecap="round" strokeLinejoin="round"/>
                                    <path
                                        d="M21 9C22.1046 9 23 8.10457 23 7C23 5.89543 22.1046 5 21 5C19.8954 5 19 5.89543 19 7C19 8.10457 19.8954 9 21 9Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"
                                        strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M19 7H15" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9 7H5" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                    <path
                                        d="M7.5 16.5V18.5C7.5 19.11 7.13 19.64 6.61 19.86C6.42 19.95 6.22 20 6 20H4C3.17 20 2.5 19.33 2.5 18.5V16.5C2.5 15.67 3.17 15 4 15H6C6.83 15 7.5 15.67 7.5 16.5Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"
                                        strokeLinecap="round" strokeLinejoin="round"/>
                                    <path
                                        d="M21.5 16.5V18.5C21.5 19.33 20.83 20 20 20H18C17.78 20 17.58 19.95 17.39 19.86C16.87 19.64 16.5 19.11 16.5 18.5V16.5C16.5 15.67 17.17 15 18 15H20C20.83 15 21.5 15.67 21.5 16.5Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"
                                        strokeLinecap="round" strokeLinejoin="round"/>
                                    <path
                                        d="M15 5.5V8.5C15 9.32 14.32 10 13.5 10H10.5C9.68 10 9 9.32 9 8.5V5.5C9 4.68 9.68 4 10.5 4H13.5C14.32 4 15 4.68 15 5.5Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"
                                        strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M15 7.72998C17.37 8.92998 19 11.51 19 14.5C19 14.67 18.99 14.83 18.97 15"
                                          stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5.03 15C5.01 14.83 5 14.67 5 14.5C5 11.51 6.63 8.92998 9 7.72998"
                                          stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <p>Flexibilite</p>
                            </li>
                            <li className="card-square">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.08998 13.28H9.17998V20.48C9.17998 22.16 10.09 22.5 11.2 21.24L18.77 12.64C19.7 11.59 19.31 10.72 17.9 10.72H14.81V3.52002C14.81 1.84002 13.9 1.50002 12.79 2.76002L5.21998 11.36C4.29998 12.42 4.68998 13.28 6.08998 13.28Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10"
                                        strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <p>Rapidite de mise en oeuvre</p>
                            </li>
                            <li className="card-square">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path
                                        d="M18.9299 20.6898C19.4599 22.2898 20.6699 22.4498 21.5999 21.0498C22.4499 19.7698 21.8899 18.7198 20.3499 18.7198C19.2099 18.7098 18.5699 19.5998 18.9299 20.6898Z"
                                        stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                </svg>
                                <p>Referencement naturel (SEO)</p>
                            </li>
                        </ul>
                    </div>
                </section>
                <section id="service-wordpress-plugins" className="page-padding service-presentation">
                    <div>
                        <div>
                            <h2>Plugins intégrer</h2>
                            <p>En utilisant WordPress, vous pouvez également bénéficier d’un large éventail de
                                fonctionnalités et de plugins qui vous aideront à améliorer votre référencement, à
                                intégrer des formulaires de contact, à intégrer des boutons de partage sur les réseaux
                                sociaux, et bien plus encore.</p>
                        </div>
                        <div>
                            <img onClick={() => setTogglerPlugins(!togglerPlugins)}
                                 src="https://res.cloudinary.com/seventeen-informatique/image/upload/v1704563542/website/screens/wordpress/xekl6g5puqjczt3rvhnf.png"
                                 alt="Galerie de plugins Wordpress permettant d'ajouter des fonctionnalites a votre site internet."/>
                            <FsLightbox
                                toggler={togglerPlugins}
                                sources={[
                                    "https://res.cloudinary.com/seventeen-informatique/image/upload/v1704563542/website/screens/wordpress/xekl6g5puqjczt3rvhnf.png"
                                ]}
                            />
                        </div>
                    </div>
                </section>
                <section id="service-wordpress-gutenberg" className="page-padding service-presentation">
                    <div>
                        <div>
                            <h2>Personnaliser votre contenu</h2>
                            <p>Personnalisez votre contenu en quelques clics avec l’éditeur Gutenberg de WordPress.
                                Grâce à une interface intuitive et conviviale, vous pouvez facilement mettre en forme
                                votre contenu en utilisant des fonctionnalités de drag and drop, pour créer des pages
                                dynamiques et attrayantes en un rien de temps.</p>
                        </div>
                        <div>
                            <img onClick={() => setTogglerGutenberg(!togglerGutenberg)}
                                 src="https://res.cloudinary.com/seventeen-informatique/image/upload/v1704563541/website/screens/wordpress/knwfc9pq9j0po5yifz6h.png"
                                 alt="Editeur Gutenberg permettant de modifier le contenu ainsi que l'apparence d'une page ou d'un article de blog."/>
                            <FsLightbox
                                toggler={togglerGutenberg}
                                sources={[
                                    "https://res.cloudinary.com/seventeen-informatique/image/upload/v1704563541/website/screens/wordpress/knwfc9pq9j0po5yifz6h.png"
                                ]}
                            />
                        </div>
                    </div>
                </section>
                <section className="page-padding">
                    <Citation author="Corentin S.E."
                              avatar="https://res.cloudinary.com/seventeen-informatique/image/upload/v1704554038/identity/xcu8vy3lrfrkmswvwamz.jpg"
                              content="Chez SEVENTEEN INFORMATIQUE, passionné en matière de développement Web et de création de contenu. Nous sommes fiers de créer des sites web de qualité supérieure pour nos clients, avec des fonctionnalités de pointe et une conception esthétique et professionnelle. Nous pouvons également vous aider à planifier, rédiger et créer du contenu pour votre site web ou votre blog, afin de vous aider à attirer de nouveaux clients et à stimuler votre activité."/>
                </section>
                <section className="mb-5">
                    <CTA title="Un projet en tête ?"
                         text="Prêt à démarrer votre projet ? Contactez-nous dès maintenant et discutons de vos besoins. Nous sommes là pour vous aider à concrétiser vos idées et à atteindre vos objectifs numériques. Cliquez ci-dessous pour nous rejoindre."
                         button="Contactez-nous" link="/contact"
                         picture="https://res.cloudinary.com/seventeen-informatique/image/upload/v1704555157/website/illustrations/kcaqlyez0codwq7gnqnn.svg"/>
                </section>
            </main>
        </div>
    );
}