import React, { useState, useEffect } from "react";
import CardRealisation from "../cards/realisation";

function RealisationsList({ realisations }) {
    return (
        <>
            {realisations && realisations.length > 0 && realisations.map((realisation, index) => (
                <CardRealisation
                    key={index}
                    title={realisation.title}
                    description={realisation.categorie}
                    picture={realisation.picture}
                    link={realisation.link}
                />
            ))}
        </>
    );
}

export default function RealisationsGrid({limit, setRealisationsCount}) {
    const [realisations, setRealisations] = useState([]);

    useEffect(() => {
        async function getRealisations() {
            try {
                const url = `${process.env.REACT_APP_TESSA_URL}/api/v2/portfolio/realisations?limit=${limit}`;
                const apiKey = process.env.REACT_APP_TESSA_APIKEY;

                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`
                };

                // Appel de l'API
                const response = await fetch(url, {
                    method: 'GET',
                    headers: headers,
                });

                if (!response.ok) {
                    throw new Error(`Erreur lors de la requête : ${response.status}`);
                }

                // Réponse de l'API
                const data = await response.json();
                setRealisations(data.realisations);
                if(setRealisationsCount !== undefined) {
                    setRealisationsCount(data.realisations.length);
                }
            } catch (error) {
                console.error('Une erreur s\'est produite :', error.message);
            }
        }

        getRealisations();
    }, []);

    return (
        <>
            <RealisationsList realisations={realisations} />
        </>
    );
}
