import React from "react";
import RealisationsGrid from "../../components/realisations/grid";
import CTA from "../../components/generic/cta";

export default function PortfolioCsamard(){
    const [realisationsCount, setRealisationsCount] = React.useState(0);
    return (
        <div id={'profile-team-page'}>
            <header>
                <div>
                    <div>
                        <h2>Corentin SAMARD--EYMONERIE</h2>
                        <p>Développeur Web</p>
                        <p className={'socials'}>
                            <a href="https://www.linkedin.com/in/corentin-samard-eymonerie-b3913a252/" target="_blank"
                               title="Linkedin Profile">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 23.6" width="24pt"
                                     height="23.6pt">
                                    <defs>
                                        <clipPath id="_clipPath_hfPxekkb0vd1lzCIsc17Hqpa1Np2cvUs">
                                            <rect width="24" height="23.6"/>
                                        </clipPath>
                                    </defs>
                                    <g clip-path="url(#_clipPath_hfPxekkb0vd1lzCIsc17Hqpa1Np2cvUs)">
                                        <path
                                            d=" M 21.637 0 L 2.363 0 C 1.183 0 0.2 0.983 0.2 2.163 L 0.2 21.437 C 0.2 22.617 1.183 23.6 2.363 23.6 L 21.637 23.6 C 22.817 23.6 23.8 22.617 23.8 21.437 L 23.8 2.163 C 23.8 0.983 22.817 0 21.637 0 Z  M 7.083 19.667 L 3.15 19.667 L 3.15 8.85 L 7.083 8.85 L 7.083 19.667 Z  M 5.117 7.572 C 3.937 7.572 3.15 6.783 3.15 5.8 C 3.15 4.721 3.937 3.933 5.117 3.933 C 6.297 3.933 7.083 4.72 7.083 5.703 C 7.083 6.783 6.297 7.572 5.117 7.572 Z  M 20.85 19.667 L 16.917 19.667 L 16.917 13.767 C 16.917 12.191 15.833 11.8 15.542 11.8 C 15.245 11.8 13.967 11.997 13.967 13.767 L 13.967 19.667 L 10.033 19.667 L 10.033 8.85 L 13.967 8.85 L 13.967 10.425 C 14.559 9.537 15.542 8.85 17.408 8.85 C 19.275 8.85 20.85 10.325 20.85 13.767 L 20.85 19.667 Z "
                                            fill="rgb(255,255,255)"/>
                                    </g>
                                </svg>
                            </a>
                            <a href="https://github.com/cogendarmerie/" target="_blank" title="Github Profile">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 23.6" width="24pt"
                                     height="23.6pt">
                                    <defs>
                                        <clipPath id="_clipPath_CUb3L6SAsxU8H8B2W2pxQBbGKo4AmI1B">
                                            <rect width="24" height="23.6"/>
                                        </clipPath>
                                    </defs>
                                    <g clip-path="url(#_clipPath_CUb3L6SAsxU8H8B2W2pxQBbGKo4AmI1B)">
                                        <path
                                            d=" M 12.01 0 C 5.369 0 0 5.408 0 12.099 C 0 17.448 3.44 21.975 8.212 23.577 C 8.809 23.698 9.027 23.317 9.027 22.996 C 9.027 22.716 9.008 21.755 9.008 20.753 C 5.667 21.474 4.971 19.31 4.971 19.31 C 4.434 17.908 3.639 17.548 3.639 17.548 C 2.545 16.807 3.718 16.807 3.718 16.807 C 4.931 16.887 5.568 18.049 5.568 18.049 C 6.641 19.891 8.371 19.371 9.067 19.05 C 9.166 18.269 9.485 17.728 9.823 17.428 C 7.158 17.147 4.355 16.106 4.355 11.458 C 4.355 10.136 4.832 9.054 5.587 8.213 C 5.468 7.913 5.05 6.67 5.707 5.008 C 5.707 5.008 6.721 4.687 9.007 6.25 C 9.986 5.985 10.996 5.85 12.01 5.849 C 13.024 5.849 14.058 5.989 15.012 6.25 C 17.299 4.687 18.313 5.008 18.313 5.008 C 18.969 6.67 18.552 7.913 18.432 8.213 C 19.208 9.054 19.665 10.136 19.665 11.458 C 19.665 16.106 16.862 17.127 14.177 17.428 C 14.615 17.808 14.992 18.529 14.992 19.671 C 14.992 21.294 14.973 22.596 14.973 22.996 C 14.973 23.317 15.192 23.698 15.788 23.577 C 20.56 21.975 24 17.448 24 12.099 C 24.02 5.408 18.631 0 12.01 0 Z "
                                            fill-rule="evenodd" fill="rgb(255,255,255)"/>
                                    </g>
                                </svg>
                            </a>
                        </p>
                    </div>
                </div>
                <div>
                    <picture className="header-geometrical">
                        <source srcSet="https://empty.fr/picture.jpg" media="(max-width: 958px)"/>
                        <img
                             src="https://res.cloudinary.com/seventeen-informatique/image/upload/v1706022970/website/illustrations/gjsdvwb0s3epxx6eh00q.svg"
                             alt="Forme géométrique" aria-hidden="true"/>
                    </picture>
                </div>
            </header>
            <main>
                <section id="team-presentation" className="page-padding">
                    <div>
                        <div className="mb-4">
                            <h2 className="mb-1">Bienvenue sur mon profil !</h2>
                            <p>Je suis un étudiant passionné de technologie, en quête constante d'apprentissage et de
                                croissance. En parallèle de mes études, je suis également un développeur web freelance,
                                où je transforme des idées en sites web fonctionnels et attrayants.</p>
                        </div>
                        <div className="mb-4">
                            <h3 className="fs-5 mb-1">Ce que je fais :</h3>
                            <ul className="list">
                                <li>💻 Développement Web : Création de sites web sur mesure pour répondre aux besoins
                                    uniques de mes clients.
                                </li>
                                <li>📚 Études : Je suis constamment en train d'explorer de nouvelles technologies et de
                                    perfectionner mes compétences.
                                </li>
                                <li>💡 Passion : J'adore explorer les dernières tendances en développement web et rester
                                    à la pointe de la technologie.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="fs-5 mb-1">Pourquoi travailler avec moi ?</h3>
                            <ul className="list">
                                <li>🤝 Collaboration : Je travaille en étroite collaboration avec mes clients pour
                                    transformer leurs visions en réalité numérique.
                                </li>
                                <li>🚀 Créativité : J'apporte une approche créative à chaque projet pour garantir une
                                    expérience utilisateur exceptionnelle.
                                </li>
                                <li>📈 Croissance : Je suis déterminé à aider mes clients à réussir en ligne grâce à des
                                    solutions numériques efficaces. Que vous soyez à la recherche d'un développeur web
                                    freelance ou que vous souhaitiez discuter de technologie, n'hésitez pas à me
                                    contacter. Ensemble, nous pouvons réaliser de grandes choses !
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="page-padding">
                    <div className="mb-4">
                        <h2>Mes Compétences</h2>
                    </div>
                    <div className="mb-5">
                        <div className="row">
                            <div className="col-6 col-sm-6">
                                <div className="mb-4">
                                    <h3>Languages</h3>
                                </div>
                                <div>
                                    <h4 className="fs-5">Frontend</h4>
                                    <ul className="list">
                                        <li>HTML5</li>
                                        <li>CSS3</li>
                                        <li>JavaScript</li>
                                        <li>Twig</li>
                                    </ul>
                                </div>
                                <div>
                                    <h4 className="fs-5">Backend</h4>
                                    <ul className="list">
                                        <li>PHP</li>
                                        <li>Javascript</li>
                                        <li>C#</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-6 col-sm-6">
                                <div className="mb-4">
                                    <h3>Frameworks</h3>
                                </div>
                                <div>
                                    <ul className="list">
                                        <li>Symfony</li>
                                        <li>Codeigniter</li>
                                        <li>Next.Js</li>
                                        <li>Node.Js</li>
                                        <li>React.Js</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="page-padding portfolio-realisations">
                    <div>
                        <h2>Mes Réalisations</h2>
                        <p>{realisationsCount} Réalisations</p>
                    </div>
                </section>
                <div id="realisations-container">
                    <RealisationsGrid limit={100} setRealisationsCount={setRealisationsCount} />
                </div>
                <section className="mb-5">
                    <CTA title="Un projet en tête ?" text="Prêt à démarrer votre projet ? Contactez-nous dès maintenant et discutons de vos besoins. Nous sommes là pour vous aider à concrétiser vos idées et à atteindre vos objectifs numériques. Cliquez ci-dessous pour nous rejoindre." button="Contactez-nous" link="/contact" picture="https://res.cloudinary.com/seventeen-informatique/image/upload/v1704555157/website/illustrations/kcaqlyez0codwq7gnqnn.svg"/>
                </section>
            </main>
        </div>
    );
}