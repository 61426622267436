import React from "react";

export default  function Citation({content, author, avatar}){
    return (
        <div className="citation">
            <div className="citation-content">
                <p>"{content}"</p>
            </div>
            <div className="citation-author">
                <div>
                    <img src={avatar} aria-hidden="true" alt={`Avatar de ${author}`} />
                </div>
                <div>
                    <p>{author}</p>
                </div>
            </div>
        </div>
    );
}